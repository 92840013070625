import { EffectCube, Pagination, Navigation } from "swiper/modules";
import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cube";
import "swiper/css/pagination";
import { BsXLg } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import HeartIcon from "./imgs/whiteHeart.svg";
import RedHeart from "./imgs/RedHeart.svg";
import MessageIcon from "./imgs/MessageIcon.svg";
import instaBG from "./imgs/instaBG.jpg";
import instaBG1 from "./imgs/instaBG1.jpg";
import instaBG2 from "./imgs/instaBG2.jpg";
import instaBG3 from "./imgs/instaBG3.jpg";
import instaBG4 from "./imgs/instaBG4.jpg";
import { SlCalender } from "react-icons/sl";
import { AiFillStar } from "react-icons/ai";

export default function MobileStories() {
  const navigate = useNavigate();
  const { param } = useParams();
  
  
    const [index, setIndex] = useState(
      param
        ? isNaN(parseInt(param, 10))
          ? 0
          : Math.max(0, Math.min(parseInt(param, 10), 3))
        : 0
    );
  const [likedStory, setLikedStory] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

 

  // useEffect(() => {
  //   const handleTouch = (e: TouchEvent) => {
  //     const target = e.targetTouches[0].target as HTMLElement;

  //     if (target && target.classList) {
  //       const className = target.classList.value;
  //       alert(className);
  //       // Replace console.log with your logic, e.g., navigate("/");
  //     }
  //   };

  //   window.addEventListener('touchstart', handleTouch);

  //   // Cleanup: Remove the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('touchstart', handleTouch);
  //   };
  // }, []);

  function toggleDiv() {
    const divElement = document.getElementById("HideOnClick") as HTMLDivElement;
    const divElement2 = document.getElementById("HideOnClick2") as HTMLDivElement;



  

    // Hide the div
    divElement.classList.add("hidden");
    divElement2.classList.add("hidden");

    
    // Wait for 1 second, then show the div again
    setTimeout(() => {
      divElement.classList.remove("hidden");
      divElement2.classList.remove("hidden");

 

    }, 200);
  }
  const [height, setHeight] = useState(100);


  console.log(likedStory[index])

  useEffect(() => {
    // Update URL without reloading
    navigate(`/stories/${index}`);
  }, [index, navigate]);

  function smallLikePost(slideIndex: number) {
    let smallHeart = document.getElementById(
      "smallHeart"
    ) as HTMLImageElement;
    if (likedStory[slideIndex] && smallHeart) {
      smallHeart.src = HeartIcon;
      setLikedStory((prevLikedStory) => {
        const updatedLikedStory = [...prevLikedStory];
        updatedLikedStory[slideIndex] = false;
        return updatedLikedStory;
      });
    } else {
      if (smallHeart) {

        smallHeart.src = RedHeart;
        smallHeart.style.transform = `scale(1.5)`;

        setTimeout(() => {
          smallHeart.style.transform = `scale(0.95)`;
        }, 100);

        setTimeout(() => {
          smallHeart.style.transform = `scale(0.95)`;
        }, 200);

        setLikedStory((prevLikedStory) => {
          const updatedLikedStory = [...prevLikedStory];
          updatedLikedStory[slideIndex] = true;
          return updatedLikedStory;
        });
      }
    }
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setHeight(75 + ((window.innerWidth - 320) * 25) / (600 - 320));
      } else {
        setHeight(100);
      }
    };

    // Initial call to set the height on component mount
    handleResize();
    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  document.body.style.overflow = "hidden";

  return (
    <div
      className="w-full h-full overflow-hidden fixed "
      style={{ width: "100%", height: "100%", overflow: "hidden", position: "fixed" }}
    >
      <div className="flex justify-center" id="HideOnClick">
        {/* Timer */}
        <div className="w-11/12 h-0.5 bg-gray-200 opacity-50 absolute top-2 rounded-md z-10"></div>
        {/* End of Timer */}

        {/* Profile Header  */}
        <div className="w-10/12 flex justify-between absolute top-4 invert z-20">
          <div className="flex justify-center items-center gap-2">
            <div className="bg-black rounded-full w-10 h-10"></div>
            <p>AlexDanil</p>
            <p className="text-gray-600 ">10h</p>
          </div>
          <div className="flex items-center gap-2">
            <div className="text-5xl transform -translate-y-3.5">
              <p> ... </p>
            </div>
            <button
              className=""
              onClick={() => navigate("/")}
              onTouchStart={() => navigate("/")}
            >
              <BsXLg
                className="z-50 close-button"
                size={30}
                style={{ stroke: "black", strokeWidth: "0.5" }}
              />
            </button>
          </div>
        </div>
      </div>


            {/* bottom part send message  */}
            <div className="w-11/12 flex absolute bottom-2.5 gap-2 z-50  left-[4.16666666%]" id="HideOnClick2">
              <input
                className="rounded-3xl border-2 border-white flex-1 h-12 bg-transparent resize-none pl-4 text-sm text-white placeholder-white"
                placeholder="Send message"
              />
              <div className="flex w-16 h-12 items-center gap-2 ">
                <img
                  src={likedStory[index] ? RedHeart : HeartIcon}
                  onClick={() => smallLikePost(index)}
                  className={`cursor-pointer w-[32px] h-[32px] transform duration-100 z-50`}
                  id="smallHeart"
                />
                <img
                  src={MessageIcon}
                  className={`cursor-pointer w-[32px] h-[32px] invert`}
                  id=""
                />
              </div>
            </div>

            {/* End of bottom part send message  */}

      <Swiper
        effect={"cube"}
        grabCursor={true}
        cubeEffect={{
          shadow: true,
          slideShadows: true,
          shadowOffset: 20,
          shadowScale: 0.94,
        }}
        initialSlide={index}
        navigation={{
          nextEl: "#forwardButton",
          prevEl: "#backwardButton",
        }}
        preventClicks={false}
        preventClicksPropagation={false}
        onSlideChange={(swiper: any) => setIndex(swiper.activeIndex)}
        modules={[EffectCube, Navigation]}
        className=" w-full h-screen overflow-hidden"
        style={{ width: "100%", height: "100%", overflow: "hidden" }}
      >
        <button
          className={` fixed top-[10%] left-0 z-10  w-1/2  h-[80%]  `}
          id="backwardButton"
          onClick={() => toggleDiv()}
        ></button>
        <button
          className={` fixed top-[10%] right-0 z-10  w-1/2  h-[80%] `}
          id="forwardButton"
          onClick={() => toggleDiv()}
        ></button>
        <SwiperSlide className="overflow-hidden w-full h-screen ">
          <div className="w-full h-screen bg-black flex flex-col items-center relative overflow-hidden">
            {/* End of Profile Header  */}

            <div
              className="  min-h-[75%] my-auto w-full z-0 max-w-[610px] relative"
              style={{ height: `${height}%` }}
              id="container"
            >
              <img src={instaBG} className="w-full h-full absolute z-0"></img>
              <div className="w-10/12 mx-auto pt-24 z-20 flex flex-col gap-8 font-bold">
                <h1 className="text-center underline text-white z-20 text-3xl">
                  {" "}
                  Experience
                </h1>

                <div className=" flex flex-col text-white drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                  <h1 className="text-2xl">Fullstack Developer</h1>
                  <p className="text-red-400 text-2xl">Kedra Sverige AB</p>
                  <div className="flex items-center gap-1">
                    {" "}
                    <SlCalender color="white" />
                    <p className="pt-0.5 text-2xl"> Mar 2023 - On going</p>
                  </div>
                </div>

                <div className="flex flex-col text-white drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                  <h1 className="text-2xl"> Salesman</h1>
                  <p className="text-red-400 text-2xl"> MediaMarkt</p>
                  <div className="flex items-center gap-1">
                    {" "}
                    <SlCalender color="white" />
                    <p className="pt-0.5 text-2xl"> Sep 2021 - Mar 2022</p>
                  </div>
                </div>

                <div className="flex flex-col text-white drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                  <h1 className="text-2xl"> Personal assistant</h1>
                  <p className="text-red-400 text-2xl"> New life AB</p>
                  <div className="flex items-center gap-1">
                    {" "}
                    <SlCalender color="white" />
                    <p className="pt-0.5 text-2xl"> Jun 2019 - Sep 2021</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </SwiperSlide>

        <SwiperSlide className="overflow-hidden w-full h-screen">
          <div className="w-full h-screen bg-black flex flex-col items-center relative overflow-hidden">
            

            <div
              className="bg-blue-200  min-h-[75%] my-auto w-full z-0 max-w-[610px] relative"
              style={{ height: `${height}%` }}
              id="container"
            >
              <img src={instaBG1} className="w-full h-full absolute z-0"></img>

              <div className="w-10/12 mx-auto pt-24 z-20 flex flex-col gap-8 bg-white font-bold">
                <h1 className="text-center underline text-white z-20 text-3xl">
                  {" "}
                  Education
                </h1>

                <div className=" flex flex-col text-white  z-20 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                  <h1 className="text-2xl">Frontend Developer </h1>
                  <p className="text-red-400 text-xl">Jensen Education</p>
                  <div className="flex items-center gap-1">
                    {" "}
                    <SlCalender color="white" />
                    <p className="pt-0.5 text-xl"> 2021 - 2023</p>
                  </div>
                </div>

                <div className="flex flex-col text-white  z-20 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                  <h1 className="text-2xl">Computer Science</h1>
                  <p className="text-red-400 text-xl">KTH</p>
                  <div className="flex items-center gap-1">
                    {" "}
                    <SlCalender color="white" />
                    <p className="pt-0.5 text-xl"> 2020 - 2021</p>
                  </div>
                </div>

                <div className="flex flex-col text-white  z-20 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                  <h1 className="text-2xl">It & Media</h1>
                  <p className="text-red-400 text-xl">Huddinge Gymnasiet</p>
                  <div className="flex items-center gap-1">
                    {" "}
                    <SlCalender color="white" />
                    <p className="pt-0.5 text-xl"> 2017 - 2020</p>
                  </div>
                </div>
              </div>
            </div>

           
          </div>
        </SwiperSlide>

        <SwiperSlide className="overflow-hidden w-full h-screen">
          <div className="w-full h-screen bg-black flex flex-col items-center relative overflow-hidden">
            

            <div
              className="bg-green-200 relative min-h-[75%] my-auto w-full z-0 max-w-[610px] "
              style={{ height: `${height}%` }}
              id="container"
            >
              <img src={instaBG2} className="w-full h-full absolute z-0"></img>

              <div className="w-10/12 mx-auto pt-24 z-20 flex flex-col gap-8 bg-white font-bold ">
                <h1 className="text-center underline text-white z-20 text-3xl">
                  {" "}
                  Competence{" "}
                </h1>

                <div className=" flex flex-col text-white z-20 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] text-center">
                  <h1 className="text-2xl">Frontend </h1>
                  <p className="text-red-400 text-xl">
                    HTML, CSS, JavaScript, ReactJS, BootStrap, Tailwind,
                    TypeScript
                  </p>
                </div>

                <div className="flex flex-col text-white z-20 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] text-center">
                  <h1 className="text-2xl">Backend</h1>
                  <p className="text-red-400 text-xl">
                    NodeJs, NextJs, ExpressJs, Python, C#
                  </p>
                </div>

                <div className="flex flex-col text-white z-20 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] text-center">
                  <h1 className="text-2xl">DataBase</h1>
                  <p className="text-red-400 text-xl">
                    AWS, MongoDB, MySQL, SQLite3
                  </p>
                </div>
              </div>
            </div>

            
          </div>
        </SwiperSlide>

        <SwiperSlide className="overflow-hidden w-full h-screen">
          <div className="w-full h-screen bg-black flex flex-col items-center relative overflow-hidden">
           

            <div
              className="bg-yellow-200 relative  min-h-[75%] my-auto w-full z-0 max-w-[610px] "
              style={{ height: `${height}%` }}
              id="container"
            >
              <img src={instaBG3} className="w-full h-full absolute z-0"></img>
              <div className="w-9/12 mx-auto pt-24 z-20 flex flex-col gap-2 bg-white font-bold pl-2 text-white">
                <h1 className="text-center underline text-white z-20 text-3xl">
                  {" "}
                  Strengths
                </h1>
                <div className="flex flex-wrap gap-4">
                  <div className="border border-white rounded-xl bg-gray-200 bg-opacity-25 p-2 py-0.5 z-20 text-xl flex justify-center items-center ">
                    <p className="drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                      {" "}
                      Analytical{" "}
                    </p>
                  </div>

                  <div className="border border-white rounded-xl bg-gray-200 bg-opacity-25 p-2 py-0.5 z-20 text-xl flex justify-center items-center ">
                    <p className="drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                      {" "}
                      Problem Solver{" "}
                    </p>
                  </div>

                  <div className="border border-white rounded-xl bg-gray-200 bg-opacity-25 p-2 py-0.5 z-20 text-xl flex justify-center items-center ">
                    <p className="drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                      {" "}
                      Patient{" "}
                    </p>
                  </div>

                  <div className="border border-white rounded-xl bg-gray-200 bg-opacity-25 p-2 py-0.5 z-20 text-xl flex justify-center items-center ">
                    <p className="drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                      {" "}
                      Fast Learner{" "}
                    </p>
                  </div>

                  <div className="border border-white rounded-xl bg-gray-200 bg-opacity-25 p-2 py-0.5 z-20 text-xl flex justify-center items-center ">
                    <p className="drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                      {" "}
                      Git{" "}
                    </p>
                  </div>

                  <div className="border border-white rounded-xl bg-gray-200 bg-opacity-25 p-2 py-0.5 z-20 text-xl flex justify-center items-center ">
                    <p className="drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                      {" "}
                      Agile{" "}
                    </p>
                  </div>

                  <div className="border border-white rounded-xl bg-gray-200 bg-opacity-25 p-2 py-0.5 z-20 text-xl flex justify-center items-center ">
                    <p className="drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                      {" "}
                      Scrum{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            
          </div>
        </SwiperSlide>

        <SwiperSlide className="overflow-hidden w-full h-screen">
          <div className="w-full h-screen bg-black flex flex-col items-center relative overflow-hidden">


            <div
              className="bg-purple-200 relative  min-h-[75%] my-auto w-full z-0 max-w-[610px] "
              style={{ height: `${height}%` }}
              id="container"
            >
              <img src={instaBG4} className="w-full h-full absolute z-0"></img>

              <div className="w-10/12 mx-auto pt-24 z-20 flex flex-col gap-2 bg-white font-bold ">
                <h1 className=" text-3xl text-center underline text-white z-20 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                  {" "}
                  Langauges{" "}
                </h1>

                <div className=" flex flex-col gap-8 mt-8 text-white text-xl z-20 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                  <h1 className="text-2xl text-center">Swedish </h1>
                  <div className="flex w-full justify-center gap-1">
                    <AiFillStar color={"#F8719D"} size={40} />
                    <AiFillStar color={"#F8719D"} size={40} />
                    <AiFillStar color={"#F8719D"} size={40} />
                    <AiFillStar color={"#F8719D"} size={40} />
                    <AiFillStar color={"#F8719D"} size={40} />
                  </div>

                  <h1 className="text-2xl text-center">English </h1>
                  <div className="flex w-full justify-center gap-1">
                    <AiFillStar color={"#F8719D"} size={40} />
                    <AiFillStar color={"#F8719D"} size={40} />
                    <AiFillStar color={"#F8719D"} size={40} />
                    <AiFillStar color={"#F8719D"} size={40} />
                    <AiFillStar color={"#F8719D"} size={40} />
                  </div>

                  <h1 className="text-2xl text-center">Arabic </h1>
                  <div className="flex w-full justify-center gap-1">
                    <AiFillStar color={"#F8719D"} size={40} />
                    <AiFillStar color={"#F8719D"} size={40} />
                    <AiFillStar color={"#F8719D"} size={40} />
                    <AiFillStar color={"#F8719D"} size={40} />
                    <AiFillStar color={"#F8719D"} size={40} />
                  </div>
                </div>
              </div>
            </div>


          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
