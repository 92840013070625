import HomeIcon from "./imgs/HomeIcon.svg";
import SearchIcon from "./imgs/SearchIcon.svg";
import HeartIcon from "./imgs/HeartIcon.svg";
import CreateIcon from "./imgs/CreateIcon.svg";
import ReelsIcon from "./imgs/ReelsIcon.svg";
import CommentIcon from "./imgs/CommentIcon.svg";
import BookmarkIcon from "./imgs/BookmarkIcon.svg";
import MessageIcon from "./imgs/MessageIcon.svg";
import explore from "./imgs/explore.svg";
import ThreadIcon from "./imgs/ThreadIcon.svg";
import {
  SiGooglescholar,
  SiExpertsexchange,
  SiGithub,
  SiLinkedin,
} from "react-icons/si";
import { GiBiceps, GiSmart } from "react-icons/gi";
import { GrLanguage } from "react-icons/gr";
import InstagramLogo from "./imgs/InstagramLogo";
import instagramIcon from "./imgs/instagramIcon.svg";

import MenuLogo from "./imgs/MenuLogo";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

export default function Sidebar() {
  const navigate = useNavigate();
  const [windowSizeBolean, setWindowSizeBolean] = useState(window.innerWidth < 1280)


  useEffect(() => {
    const handleResize = () => {
      // Your resize event handling logic goes here
      setWindowSizeBolean(window.innerWidth < 1280) 
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div className="md:p-4 xl:w-[384px] border-t md:border-r md:h-screen flex md:flex-col justify-between fixed bottom-0 z-50 w-full md:w-auto bg-white pointer-events-none ">
        <div className="flex md:flex-col gap-5 justify-between w-11/12 md:w-full mx-auto items-center">

          <div className=" w-full md:w-[90%] ml-4 mt-12 mb-6 md:flex hidden items-center ">
            <div
              className="pointer-events-auto cursor-pointer"
              onClick={() => navigate("/")}
            >
              { windowSizeBolean ? <img src={instagramIcon} className="w-[34px] h-[34px]"/> : <InstagramLogo /> } 
            </div>
          </div>

          <div
            className="w-1/5 md:w-[90%] h-12 hover:bg-gray-100 flex md:p-2 rounded-lg items-center justify-center md:justify-start gap-4  cursor-pointer pointer-events-auto group"
            onClick={() => navigate("/stories/0")}
          >
            {/* <img src={SearchIcon} className="w-[32px] h-[32px]"/> */}
            <div className="w-[34px] h-[34px] flex items-center justify-center">
              <SiExpertsexchange className="w-[32px] h-[32px] group-hover:w-[33px] group-hover:h-[33px]  transform duration-75 " />
            </div>
            <p className="hidden xl:block"> Experience </p>
          </div>

          <div
            className="w-1/5 md:w-[90%] hover:bg-gray-100 flex p-2 rounded-lg items-center justify-center md:justify-start gap-4 cursor-pointer pointer-events-auto group"
            onClick={() => navigate("/stories/1")}
          >
            <div className="w-[34px] h-[34px] flex items-center justify-center">
              <SiGooglescholar className="w-[32px] h-[32px] group-hover:w-[33px] group-hover:h-[33px]  transform duration-75 " />
            </div>
            <p className="hidden xl:block"> Education </p>
          </div>

          <div
            className="w-1/5 md:w-[90%] hover:bg-gray-100 flex p-2 rounded-lg items-center justify-center md:justify-start gap-4 cursor-pointer pointer-events-auto group"
            onClick={() => navigate("/stories/3")}
          >
            {/* <img src={ReelsIcon} className="w-[32px] h-[32px]"/> */}
            <div className="w-[34px] h-[34px] flex items-center justify-center">
              <GiSmart className="w-[32px] h-[32px] group-hover:w-[33px] group-hover:h-[33px]  transform duration-75 " />
            </div>
            <p className="hidden xl:block"> Competence </p>
          </div>
          
          <div
            className="w-1/5 md:w-[90%] hover:bg-gray-100 flex p-2 rounded-lg items-center justify-center md:justify-start gap-4 cursor-pointer pointer-events-auto group"
            onClick={() => navigate("/stories/2")}
          >
            {/* <img src={explore} className="w-[32px] h-[32px]"/> */}
            <div className="w-[34px] h-[34px] flex items-center justify-center">
              <GiBiceps className="w-[32px] h-[32px] group-hover:w-[33px] group-hover:h-[33px]  transform duration-75 " />
            </div>
            <p className="hidden xl:block"> Strengths </p>
          </div>

          <div
            className="w-1/5 md:w-[90%] hover:bg-gray-100 flex p-2 rounded-lg items-center justify-center md:justify-start gap-4 cursor-pointer pointer-events-auto group"
            onClick={() => navigate("/stories/4")}
          >
            {/* <img src={MessageIcon} className="w-[32px] h-[32px]"/> */}
            <div className="w-[34px] h-[34px] flex items-center justify-center">
              <GrLanguage className="w-[32px] h-[32px] group-hover:w-[33px] group-hover:h-[33px]  transform duration-75 " />
            </div>
            <p className="hidden xl:block"> Langauges </p>
          </div>
        </div>

        {/* Bottom Section  */}

        <div className="flex-col gap-8 mb-12 items-center md:flex hidden">
          <div
            className="w-[90%] hover:bg-gray-100 flex p-2 rounded-lg items-center gap-4 cursor-pointer pointer-events-auto group"
            onClick={() => window.open("https://github.com/alexDanilMain")}

          >
            <div className="w-[34px] h-[34px] flex items-center justify-center">
              <SiGithub className="w-[32px] h-[32px] group-hover:w-[33px] group-hover:h-[33px]  transform duration-75 " />
            </div>
            <p className="hidden xl:block"> LinkedIn </p>
          </div>

          <div
            className="w-[90%] hover:bg-gray-100 flex p-2 rounded-lg items-center gap-4 cursor-pointer pointer-events-auto group"
            onClick={() => window.open("https://www.linkedin.com/in/alex-danil-0125911aa/")}

          >
            <div className="w-[34px] h-[34px] flex items-center justify-center">
              <SiLinkedin className="w-[32px] h-[32px] group-hover:w-[33px] group-hover:h-[33px]  transform duration-75 " />
            </div>
            <p className="hidden xl:block"> Github </p>
          </div>
        </div>
      </div>
    </>
  );
}
