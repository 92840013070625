import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import StoriesTest from "./StoriesTest";
import MobileStories from "./MobileStories";

export default function Router() {

  const [windowSizeBolean, setWindowSizeBolean] = useState(window.innerWidth > 1280)


  useEffect(() => {
    const handleResize = () => {
      // Your resize event handling logic goes here
      setWindowSizeBolean(window.innerWidth > 1280) 
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<App />} />
          <Route path="/" element={<App />} />
          <Route path="/stories/:param" element={windowSizeBolean ? <StoriesTest /> : <MobileStories />} />

      

        </Routes>
      </BrowserRouter>
    </>
  );
}
