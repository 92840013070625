
import Sidebar from "./Sidebar";

import { useNavigate } from "react-router-dom";
import { SiGithub, SiLinkedin } from "react-icons/si";
import Posts from "./Posts";

// picture Imports
import kedraNew0 from "./imgs/kedraNew.png"
import kedraNew1 from "./imgs/kedraNew1.png"
import kedraNew2 from "./imgs/kedraNew2.png"
import kedraNew3 from "./imgs/kedraNew3.png"
import kedraNew4 from "./imgs/kedraNew4.png"
import kedraNew5 from "./imgs/kedraNew5.png"
import kedraNew6 from "./imgs/kedraNew6.png"
import kedraNew7 from "./imgs/kedraNew7.png"
import kedraNew8 from "./imgs/kedraNew8.png"

import kedraOld0 from "./imgs/kedraOld.png"
import kedraOld1 from "./imgs/kedraOld1.png"
import kedraOld2 from "./imgs/kedraOld2.png"
import kedraOld4 from "./imgs/kedraOld4.png"
import kedraOld5 from "./imgs/kedraOld5.png"
import kedraOld6 from "./imgs/kedraOld6.png"
import kedraOld7 from "./imgs/kedraOld7.png"

import WIS0 from "./imgs/WIS.png"
import WIS1 from "./imgs/WIS1.png"
import WIS2 from "./imgs/WIS2.png"
import WIS4 from "./imgs/WIS4.png"
import WIS5 from "./imgs/WIS5.png"
import WIS6 from "./imgs/WIS6.png"
import WIS7 from "./imgs/WIS7.png"
import WIS8 from "./imgs/WIS8.png"
import WIS9 from "./imgs/WIS9.png"

import kedraLogo from "./imgs/kedraLogo.svg"

import {SiGooglescholar,SiExpertsexchange} from "react-icons/si";
import { GiBiceps, GiSmart } from "react-icons/gi";
import { GrLanguage } from "react-icons/gr";

function App() {
  const navigate = useNavigate();


  const kedraNew = 
  {
    name : "Kedra Sveriga AB",
    caption :"This is a beta demo of kedras potential new website. I coded everything from scratch with designs being provided by a UIUX",
    likeCount : 4124132,
    commentCount : 1369,
    imgs : [
      kedraNew0,
  kedraNew1,
  kedraNew2,
  kedraNew3,
  kedraNew4,
  kedraNew5,
  kedraNew6,
  kedraNew7,
  kedraNew8,
    ],
    liked: false,
    logo : kedraLogo
  }


  const kedraOld = 
  {
    name : "Kedra Sveriga AB",
    caption :"Initial Kedra website, and the website kedra is currently using!",
    likeCount : 12224132,
    commentCount : 31242,
    imgs : [
      kedraOld0,
  kedraOld1,
  kedraOld2,
  kedraOld4,
  kedraOld5,
  kedraOld6,
  kedraOld7,
    ],
    liked: false,
    logo : kedraLogo
    
  }

  const WIS = 
  {
    name : "Water In Sight",
    caption :"Platform for Water in sight. Fully functional platform with chatting system, data sorting and multiple graph displaying dashboard",
    likeCount : 47218938,
    commentCount : 23420,
    imgs : [
      WIS0,
  WIS1,
  WIS2,
  WIS4,
  WIS5,
  WIS6,
  WIS7,
  WIS8,
  WIS9,

    ],
    liked: false,
    logo : "https://static.wixstatic.com/media/a2eddc_e38f996452d14cdaae9d3c22af1dd8a5%7Emv2.png/v1/fill/w_192%2Ch_192%2Clg_1%2Cusm_0.66_1.00_0.01/a2eddc_e38f996452d14cdaae9d3c22af1dd8a5%7Emv2.png"
  }

  


  
  return (
    
    <div className="w-screen h-screen flex overflow-x-hidden" >
      <Sidebar />

      <div className="w-full h-screen flex justify-center md:pl-[140px] xl:pl-[358px]">
        <div className="w-full h-screen flex ">
          <div className="w-full flex md:mt-12 justify-center">
            {/* middle Section  */}
            <div className="w-full md:w-[680px] flex flex-col pt-10 md:pt-0">
              {/* For you and follwing div  */}
              <div className="fixed top-0 bg-white md:static w-full md:w-[590px] xl:w-[680px] border-b h-10 flex gap-4 pb-12 md:pb-3 flex-shrink-1 px-4 md:px-0 md:justify-start justify-between z-50">
                
                <p className="text-3xl md:text-lg font-medium">CV</p>
                <p className="text-gray-300 font-medium hidden md:block text-lg">Alex Danil</p>
                <div className="md:hidden flex gap-4 pt-2 pr-2">
                <div className="w-[34px] h-[34px] flex items-center justify-center">
              <SiGithub className="w-[30px] h-[30px] group-hover:w-[33px] group-hover:h-[33px]  transform duration-75 " />
            </div>
            <div className="w-[34px] h-[34px] flex items-center justify-center">
              <SiLinkedin className="w-[30px] h-[30px] group-hover:w-[33px] group-hover:h-[33px]  transform duration-75 " />
            </div>
                </div>
              </div>
              {/* End of for you and follwing div  */}

              {/* Stories  */}
              <div className="w-full flex mt-6 gap-5 overflow-x-auto no-scrollbar" style={{ minHeight: '100px' }}>

                <div className="flex-col flex gap-1 items-center cursor-pointer"onClick={()=>navigate("/stories/0")}>
                  <div className="w-[3.75rem] h-[3.75rem] rounded-full bg-gradient-to-tr from-[#f9ce34] via-[#ee2a7b] to-[#6228d7] flex justify-center items-center">
                    <div className="bg-white w-14 h-14 rounded-full">
              <SiExpertsexchange className="w-full h-full p-3" />
                      
                    </div>
                  </div>
                  <p className="truncate w-24 text-sm text-center">
                    Experience
                  </p>
                </div>

                <div className="flex-col flex gap-1 items-center cursor-pointer" onClick={()=>navigate("/stories/1")}>
                  <div className="w-[3.75rem] h-[3.75rem] rounded-full bg-gradient-to-tr from-[#f9ce34] via-[#ee2a7b] to-[#6228d7] flex justify-center items-center">
                    <div className="bg-white w-14 h-14 rounded-full">
              <SiGooglescholar className="w-full h-full p-3" />

                    </div>
                  </div>
                  <p className="truncate w-24 text-sm text-center">
                    Educations
                  </p>
                </div>

                <div className="flex-col flex gap-1 items-center cursor-pointer" onClick={()=>navigate("/stories/2")}>
                  <div className="w-[3.75rem] h-[3.75rem] rounded-full bg-gradient-to-tr from-[#f9ce34] via-[#ee2a7b] to-[#6228d7] flex justify-center items-center">
                    <div className="bg-white w-14 h-14 rounded-full">
              <GiSmart className="w-full h-full p-3" />

                    </div>
                  </div>
                  <p className="truncate w-24 text-sm text-center">
                    Competence
                  </p>
                </div>

                <div className="flex-col flex gap-1 items-center cursor-pointer" onClick={()=>navigate("/stories/3")}>
                  <div className="w-[3.75rem] h-[3.75rem] rounded-full bg-gradient-to-tr from-[#f9ce34] via-[#ee2a7b] to-[#6228d7] flex justify-center items-center">
                    <div className="bg-white w-14 h-14 rounded-full">
              <GiBiceps className="w-full h-full p-3" />

                    </div>
                  </div>
                  <p className="truncate w-24 text-sm text-center">Strengths</p>
                </div>

                <div className="flex-col flex gap-1 items-center cursor-pointer" onClick={()=>navigate("/stories/4")}>
                  <div className="w-[3.75rem] h-[3.75rem] rounded-full bg-gradient-to-tr from-[#f9ce34] via-[#ee2a7b] to-[#6228d7] flex justify-center items-center">
                    <div className="bg-white w-14 h-14 rounded-full">
              <GrLanguage className="w-full h-full p-3" />

                    </div>
                  </div>
                  <p className="truncate w-24 text-sm text-center">Langauges</p>
                </div>
              </div>
              {/* End of Stories  */}
                <Posts imgArray={kedraNew.imgs} postName={kedraNew.name} likeCountProp={kedraNew.likeCount} likeBolean={kedraNew.liked} commentCount={kedraNew.commentCount} caption={kedraNew.caption} unique ={0} logo={kedraNew.logo}></Posts>
                <Posts imgArray={kedraOld.imgs} postName={kedraOld.name} likeCountProp={kedraOld.likeCount} likeBolean={kedraOld.liked} commentCount={kedraOld.commentCount} caption={kedraOld.caption} unique ={1} logo={kedraOld.logo}></Posts>
                <Posts imgArray={WIS.imgs} postName={WIS.name} likeCountProp={WIS.likeCount} likeBolean={WIS.liked} commentCount={WIS.commentCount} caption={WIS.caption} unique ={2} logo={WIS.logo}></Posts>
  
            </div>
            {/* End of middle Section  */}
          </div>
        </div>
      </div>
      {/* <img className='' src={AllIcons}></img> */}
    </div>
  );
}

export default App;
