import FilledHeart from "./imgs/FilledHeart.svg";
import RedHeart from "./imgs/RedHeart.svg";
import CommentIcon from "./imgs/CommentIcon.svg";
import BookmarkIcon from "./imgs/BookmarkIcon.svg";
import MessageIcon from "./imgs/MessageIcon.svg";
import { useState } from "react";
import HeartIcon from "./imgs/HeartIcon.svg";



// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

// import required modules
import { Navigation } from 'swiper/modules';
export default function Posts( props : any){
  const {imgArray, postName, likeCountProp, likeBolean, commentCount, caption, unique, logo } = props

    const [likedPost, setLikedPost] = useState(likeBolean)
    const [likeCount, setLikeCount] = useState(likeCountProp)


    const kedraImages = [
        ...imgArray
    ]; 
    
    // name
    // imgs
    // likes
    // likeBolean
    // comments
    // title
  

    const [lastTouchTime, setLastTouchTime] = useState(0);
    const [likePending, setLikePending] = useState(false);
  
    const handleTouchStart = () => {
      const now = Date.now();
      const timeDiff = now - lastTouchTime;
  
      if (timeDiff < 100 && !likePending) {
        // Double tap detected
        console.log('Double tap');
        setLikePending(true);
  
        // Simulate likePost function
        likePost();
  
        // Reset the likePending status after a delay (adjust as needed)
        setTimeout(() => {
          setLikePending(false);
        }, 1000);
      }
  
      setLastTouchTime(now);
    };
  

  function likePost(){
 

    let heart = document.getElementById("like"+unique) as HTMLImageElement
    let smallHeart = document.getElementById("smallHeart"+unique) as HTMLImageElement

    if(heart && smallHeart){
      heart.classList.remove("hidden")
      heart.style.transform = `scale(3.5)`;
      
      if(!likedPost){
        setLikeCount(likeCount + 1)
        smallHeart.classList.remove("hover:opacity-50")
        smallHeart.style.transform = `scale(1.5)`
        smallHeart.src = RedHeart;
        
      }

      setTimeout(()=>{
        heart.style.transform = `scale(2.5)`

        if(!likedPost){
          smallHeart.style.transform = `scale(0.95)`
        }

      }, 100)
  
      setTimeout(()=>{
        heart.style.transform = `scale(3.5)`

      }, 200)
      setTimeout(()=>{
        heart.style.transform = `scale(0)`
      }, 800)
      setLikedPost(true)
    }


  }

  function smallLikePost(){


    let smallHeart = document.getElementById("smallHeart" +unique) as HTMLImageElement

      if(likedPost && smallHeart){
        smallHeart.classList.add("hover:opacity-50")

        smallHeart.src = HeartIcon
        setLikedPost(false)
        setLikeCount(likeCount - 1)

      }
      else{

        if(smallHeart){
          smallHeart.classList.remove("hover:opacity-50")
          smallHeart.src = RedHeart;
          smallHeart.style.transform = `scale(1.5)`
          setLikeCount(likeCount + 1)
  
    
    
          setTimeout(()=>{
            smallHeart.style.transform = `scale(0.95)`
    
          }, 100)
      
          setTimeout(()=>{
            smallHeart.style.transform = `scale(0.95)`
          }, 200)

        
    
        setLikedPost(true)
        }
      }

  }


  

    return(
    <>
                {/* Posts  */}
                <div className="w-full md:w-3/4 mx-auto mt-10">
                <div className="flex flex-col w-full md:w-[505px] flex-shrink-0">
                  {/* Title  */}
                  <div className="w-full flex">

                    <div className="flex w-full justify-between px-4 md:px-0 border-b pb-4">
                      <div className="flex">
                        <div className="w-10 h-10 rounded-full bg-gradient-to-tr from-[#f9ce34] via-[#ee2a7b] to-[#6228d7] flex justify-center items-center">
                          <div className="bg-white w-9 h-9 rounded-full"> 
                            <img src={logo} className="w-full h-full object-contain p-1"></img>
                          </div>
                        </div>

                        <div className="flex items-center ml-2 gap-2">
                          <p>{postName}</p>{" "}
                          <div className="w-1 h-1 bg-black rounded-full"></div>{" "}
                          <p className="text-gray-400">22h</p>
                        </div>
                      </div>

                      <div className="text-xl pr-4 md:pr-0"> ... </div>
                    </div>

                  </div>
                  {/* End of Title  */}

                  {/* Post Images  */}
                  <div className="bg-black w-full min-h-[600px]  flex relative" onDoubleClick={()=>likePost()} onTouchStart={handleTouchStart} >
                  <Swiper modules={[Navigation]} className="mySwiper group relative h-full min-h-[600px]" navigation={{ nextEl: "#forwardButton", prevEl: "#backwardButton" }} >
      {kedraImages.map((image, index) => (
        <SwiperSlide key={`newKedra-${index}`} className="w-full h-full min-h-[600px]">
          <div className="w-full flex items-center h-full justify-center">
            <img src={image} alt={`kedra-${index}`} className="object-contain h-full" />
          </div>
        </SwiperSlide>
      ))}

      <button
        className={`bg-slate-100 opacity-50 h-[30px] w-[30px] flex text-2xl pb-0.5 pl-1 justify-center items-center rounded-full cursor-pointer group-hover:opacity-75 absolute top-1/2 right-4 z-20`}
        id="forwardButton"
        onDoubleClick={(e)=> e.stopPropagation()}

      
      >
        {">"}
      </button>

      <button
        className={`bg-slate-100 opacity-50 h-[30px] w-[30px] flex text-2xl pb-0.5 justify-center items-center rounded-full cursor-pointer group-hover:opacity-75 absolute top-1/2 left-4 z-20`}
        id="backwardButton"
        onDoubleClick={(e)=> e.stopPropagation()}
      >
        {"<"}
      </button>
    </Swiper>
                  <img id={"like" +unique} src={FilledHeart} className="select-none opacity-90 pointer-events-none w-[32px] h-[32px] transform duration-100 absolute left-[48%] top-[47%] hidden z-50" />

                  </div>
                  {/* end of post images  */}

                  {/* Icons  */}
                  <div className="flex w-full justify-between items-center mt-2 md:px-0 px-4">
                    <div className="flex gap-2 select-none ">
                      <img src={HeartIcon} className={`cursor-pointer w-[32px] h-[32px] transform duration-100 hover:opacity-50`} id={"smallHeart" + unique} onClick={()=>smallLikePost()} />
                      <img src={CommentIcon} className="cursor-pointer hover:opacity-50 w-[32px] h-[32px]  ml-2" />
                      <img src={MessageIcon} className="cursor-pointer hover:opacity-50 w-[32px] h-[32px] " />
                    </div>
                    <div className="flex select-none ">
                      <img src={BookmarkIcon} className="cursor-pointer hover:opacity-50 w-[32px] h-[32px]" />
                    </div>
                  </div>
                  {/* end of icons  */}
                  <div className="w-full md:px-0 px-4">
                    <p className="font-medium text-sm mt-2 select-none "> {likeCount.toLocaleString()} likes</p>
                  </div>

                  <div className="w-full md:px-0 px-4">

                    <div className=" text-sm mt-2 select-none flex flex-wrap"> <p className="font-medium">AlexDanil <span className="font-normal"> {caption}  </span> </p>   </div>
                  </div>

                  <div className="w-full md:px-0 px-4">
                    <p className="text-gray-400 text-sm mt-4"> View all {commentCount} comments</p>
                  </div>


                  <div className="w-full flex flex-col">
                    <p className="text-gray-400 text-sm mt-1"> </p>
                    <textarea className="border-b resize-none h-12 overflow-hidden focus:outline-0 text-sm pl-4 md:pl-0" placeholder="Add a comment"></textarea>
                  </div>



                </div>
              </div>
              {/* end of posts */}
    </>
    )
}