import { useEffect, useState } from "react";
import InstagramLogo from "./imgs/InstagramLogo";
import { BsXLg } from "react-icons/bs";
import { useParams, useNavigate } from "react-router-dom";
import HeartIcon from "./imgs/HeartIcon.svg";
import RedHeart from "./imgs/RedHeart.svg";
import MessageIcon from "./imgs/MessageIcon.svg";
import instaBG from "./imgs/instaBG.jpg";
import instaBG1 from "./imgs/instaBG1.jpg";
import instaBG2 from "./imgs/instaBG2.jpg";
import instaBG3 from "./imgs/instaBG3.jpg";
import instaBG4 from "./imgs/instaBG4.jpg";
import { SlCalender } from "react-icons/sl";
import {AiFillStar} from "react-icons/ai"
export default function StoriesTest() {
  const navigate = useNavigate();
  const { param } = useParams();

  const [index, setIndex] = useState(
    param
      ? isNaN(parseInt(param, 10))
        ? -1
        : Math.max(-1, Math.min(parseInt(param, 10) - 1, 3))
      : 0
  );
  useEffect(() => {
    if (index >= 0) {
      moveCourselForword();
    } else setIndex(0);
  }, []);

  useEffect(() => {
    // Update URL without reloading
    navigate(`/stories/${index}`);

    let smallHeart = document.getElementById("smallHeart") as HTMLImageElement;
    if (likedStory[index]) {
      smallHeart.src = RedHeart;
      smallHeart.classList.remove("invert");
    } else {
      smallHeart.classList.add("invert");
      smallHeart.src = HeartIcon;
    }
  }, [index, navigate]);

  const [likedStory, setLikedStory] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  function smallLikePost(slideIndex: number) {
    let smallHeart = document.getElementById("smallHeart") as HTMLImageElement;
    if (likedStory[slideIndex] && smallHeart) {
      smallHeart.classList.add("invert");
      smallHeart.src = HeartIcon;
      setLikedStory((prevLikedStory) => {
        const updatedLikedStory = [...prevLikedStory];
        updatedLikedStory[slideIndex] = false;
        return updatedLikedStory;
      });
    } else {
      if (smallHeart) {
        smallHeart.classList.remove("invert");
        smallHeart.src = RedHeart;
        smallHeart.style.transform = `scale(1.5)`;

        setTimeout(() => {
          smallHeart.style.transform = `scale(0.95)`;
        }, 100);

        setTimeout(() => {
          smallHeart.style.transform = `scale(0.95)`;
        }, 200);

        setLikedStory((prevLikedStory) => {
          const updatedLikedStory = [...prevLikedStory];
          updatedLikedStory[slideIndex] = true;
          return updatedLikedStory;
        });
      }
    }
  }

  function moveCourselForword() {
    
    let forward = document.getElementById("forwardButton") as HTMLButtonElement;
    let backward = document.getElementById(
      "backwardButton"
    ) as HTMLButtonElement;
    forward.disabled = true;
    backward.disabled = true;

    let storyHeader = document.getElementById("storyHeader") as HTMLDivElement;
    let storyFooter = document.getElementById("storyFooter") as HTMLDivElement;
    storyFooter.classList.add("hidden");
    storyHeader.classList.add("hidden");

    for (let i = 0; i <= 4; i++) {
      let RemoveScale = "scaleX(1) scaleY(1)";

      let scale = "scaleX(2.5) scaleY(3)";
      let node = document.getElementById(`${i}`) as HTMLDivElement;
      if (index === i) {
        index === 0
          ? (node.style.transform = `translateX(-${370}px)` + RemoveScale)
          : (node.style.transform =
              `translateX(-${370 + index * 220 + 150}px)` + RemoveScale);
      } else if (index + 1 === i) {
        node.style.transform = `translateX(-${370 + index * 220}px)` + scale;
      } else {
        if (i === 0) {
          node.style.transform =
            `translateX(-${370 + 220 * index}px)` + RemoveScale;
        } else if (i >= index) {
          node.style.transform =
            `translateX(-${220 * (index + 1)}px)` + RemoveScale;
        } else {
          node.style.transform =
            `translateX(-${220 * (index + 1) + 300}px)` + RemoveScale;
        }
      }
    }

    setIndex(index + 1);
    setTimeout(() => {
      forward.disabled = false;
      backward.disabled = false;
      storyFooter.classList.remove("hidden");
      storyHeader.classList.remove("hidden");
    }, 500);
  }

  function moveCourselBackward() {
  
    let forward = document.getElementById("forwardButton") as HTMLButtonElement;
    let backward = document.getElementById(
      "backwardButton"
    ) as HTMLButtonElement;
    let storyHeader = document.getElementById("storyHeader") as HTMLDivElement;
    let storyFooter = document.getElementById("storyFooter") as HTMLDivElement;

    forward.disabled = true;
    backward.disabled = true;
    forward.classList.add("opacity-0");
    backward.classList.add("opacity-0");
    storyFooter.classList.add("hidden");
    storyHeader.classList.add("hidden");

    for (let i = 0; i <= 4; i++) {
      let RemoveScale = "scaleX(1) scaleY(1)";
      let scale = "scaleX(2.5) scaleY(3)";
      let node = document.getElementById(`${i}`) as HTMLDivElement;
      let computedStyle = window.getComputedStyle(node);
      let matrix = new WebKitCSSMatrix(computedStyle.transform);

      if (index === i) {
        node.style.transform = `translateX(${
          matrix.m41 + 220 + 150
        }px) ${RemoveScale}`;
      } else if (index - 1 === i) {
        node.style.transform = `translateX(${matrix.m41 + 370}px) ${scale}`;
      } else {
        if (i === 0) {
          node.style.transform = `translateX(${
            matrix.m41 + 220
          }px) ${RemoveScale}`;
        } else if (i > index) {
          node.style.transform = `translateX(${
            matrix.m41 + 220
          }px) ${RemoveScale}`;
        } else {
          node.style.transform = `translateX(${
            matrix.m41 + 220
          }px) ${RemoveScale}`;
        }
      }
    }

    setIndex(index - 1);
    setTimeout(() => {
      forward.disabled = false;
      backward.disabled = false;
      storyFooter.classList.remove("hidden");
      storyHeader.classList.remove("hidden");
    }, 500);
  }

  return (
    <>
      <div className="w-full h-screen fixed left-0 top-0 bg-zinc-800 flex">
        <div className="invert fixed top-4 left-4">
          <InstagramLogo />
        </div>

        <div
          className="invert fixed top-4 right-8 cursor-pointer"
          onClick={() => navigate("/")}
        >
          <BsXLg size={30} style={{ stroke: "black", strokeWidth: "0.5" }} />
        </div>
        <div className="w-full flex justify-center ">
          {/* <!-- Slider main container --> */}
          <div
            id="container"
            className="w-[1400px] max-h-[1200px] h-screen my-auto bg-zinc-800 flex justify-center items-center overflow-hidden relative"
          >
            {/* Profile Header  */}
            <div
              className=" flex justify-between absolute top-8 invert z-20 transform w-[375px]"
              id="storyHeader"
            >
              <div className="flex justify-center items-center gap-2">
                <div className="bg-black rounded-full w-10 h-10"></div>
                <p>AlexDanil</p>
                <p className="text-gray-600 ">10h</p>
              </div>
              <div className="flex items-center gap-2">
                <div className="text-4xl transform -translate-y-2.5">
                  <p> ... </p>
                </div>
              </div>
            </div>
            {/* End of Profile Header  */}

            {/* bottom part send message  */}
            <div
              className="w-[412.5px] flex absolute bottom-8 gap-2 z-20"
              id="storyFooter"
            >
              <input
                className="rounded-3xl border-2 border-white flex-1 h-12 bg-transparent resize-none pl-4 text-sm text-white placeholder-white"
                placeholder="Send message"
              />
              <div className="flex w-16 h-12 items-center gap-2 ">
                <img
                  src={HeartIcon}
                  className={`cursor-pointer w-[32px] h-[32px] transform duration-100 z-20 invert`}
                  id="smallHeart"
                  onClick={() => smallLikePost(index)}
                />
                <img
                  src={MessageIcon}
                  className={`cursor-pointer w-[32px] h-[32px] invert`}
                  id=""
                />
              </div>
            </div>

            {/* End of bottom part send message  */}

            <div className="w-[180px] h-[32%]  flex-shrink-0 mx-5"></div>
            <div className="w-[180px] h-[32%]  flex-shrink-0 mx-5"></div>
            <div className="w-[180px] h-[32%]  flex-shrink-0 mx-5"></div>
            <div className="w-[180px] h-[32%]  flex-shrink-0 mx-5"></div>
            <div className="w-[180px] h-[32%]  flex-shrink-0 mx-5"></div>
            <div className="w-[180px] h-[32%] flex-shrink-0 mx-5"></div>

            <div className="flex w-[520px] h-full items-center justify-between flex-shrink-0 group z-10">
              <button
                className={`bg-slate-100 opacity-50 h-[30px] w-[30px] flex text-2xl pb-0.5 pr-0.5 justify-center items-center rounded-full cursor-pointer group-hover:opacity-100`}
                onClick={() => moveCourselBackward()}
                disabled={index === 0}
                id="backwardButton"
                style={index === 0 ? { opacity: 0 } : {}}
              >
                {"<"}
              </button>

              <div
                id="0"
                className={`w-[180px] h-[32%] max-h-[400px] scale-x-[2.5] scale-y-[3] bg-black flex-shrink-0  mx-1 flex  duration-500 transition relative`}
              >
                <img src={instaBG} className="w-full h-full absolute z-0"></img>
                <div className="w-10/12 mx-auto pt-12 z-20 flex flex-col gap-2 font-bold">
                  <h1 className="text-center underline text-white">
                    {" "}
                    Experience
                  </h1>

                  <div className=" flex flex-col text-white text-[6px] drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                    <h1 className="text-xs">Fullstack Developer</h1>
                    <p className="text-red-400">Kedra Sverige AB</p>
                    <div className="flex items-center gap-1">
                      {" "}
                      <SlCalender color="white" />
                      <p className="pt-0.5"> March 2023 - On going</p>
                    </div>
                  </div>

                  <div className="flex flex-col text-white text-[6px] drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                    <h1 className="text-xs"> Salesman</h1>
                    <p className="text-red-400"> MediaMarkt</p>
                    <div className="flex items-center gap-1">
                      {" "}
                      <SlCalender color="white" />
                      <p className="pt-0.5"> September 2021 - March 2022</p>
                    </div>
                  </div>

                  <div className="flex flex-col text-white text-[6px] drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                    <h1 className="text-xs"> Personal assistant</h1>
                    <p className="text-red-400"> New life AB</p>
                    <div className="flex items-center gap-1">
                      {" "}
                      <SlCalender color="white" />
                      <p className="pt-0.5"> June 2019 - September 2021</p>
                    </div>
                  </div>
                </div>
              </div>

              <button
                className={`bg-slate-100 opacity-50 h-[30px] w-[30px] flex text-2xl pb-0.5 pl-0.5 justify-center items-center rounded-full cursor-pointer group-hover:opacity-100 ${
                  index === 4 && "hidden"
                }`}
                onClick={() => moveCourselForword()}
                id="forwardButton"
              >
                {">"}
              </button>
            </div>

            <div
              id="1"
              className="w-[180px] max-h-[400px] h-[32%] bg-black flex-shrink-0 mx-5 duration-500 transition relative"
            >
              <img src={instaBG1} className="w-full h-full absolute z-0"></img>

              <div className="w-10/12 mx-auto pt-12 z-20 flex flex-col gap-2 bg-white font-bold">
                <h1 className="text-center underline text-white z-20">
                  {" "}
                  Education
                </h1>

                <div className=" flex flex-col text-white text-[6px] z-20 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                  <h1 className="text-xs">Frontend Developer </h1>
                  <p className="text-red-400">Jensen Education</p>
                  <div className="flex items-center gap-1">
                    {" "}
                    <SlCalender color="white" />
                    <p className="pt-0.5"> 2021 - 2023</p>
                  </div>
                </div>

                <div className="flex flex-col text-white text-[6px] z-20 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                  <h1 className="text-xs">Computer Science</h1>
                  <p className="text-red-400">KTH</p>
                  <div className="flex items-center gap-1">
                    {" "}
                    <SlCalender color="white" />
                    <p className="pt-0.5"> 2020 - 2021</p>
                  </div>
                </div>

                <div className="flex flex-col text-white text-[6px] z-20 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                  <h1 className="text-xs">It & Media</h1>
                  <p className="text-red-400">Huddinge Gymnasiet</p>
                  <div className="flex items-center gap-1">
                    {" "}
                    <SlCalender color="white" />
                    <p className="pt-0.5"> 2017 - 2020</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="2"
              className="w-[180px] max-h-[400px] h-[32%] bg-black flex-shrink-0 mx-5 duration-500 transition relative"
            >
              <img src={instaBG2} className="w-full h-full absolute z-0"></img>

              <div className="w-10/12 mx-auto pt-12 z-20 flex flex-col gap-2 bg-white font-bold ">
                <h1 className="text-center underline text-white z-20">
                  {" "}
                  Competence{" "}
                </h1>

                <div className=" flex flex-col text-white text-[6px] z-20 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] text-center">
                  <h1 className="text-xs">Frontend </h1>
                  <p className="text-red-400">
                    HTML, CSS, JavaScript, ReactJS, BootStrap, Tailwind,
                    TypeScript
                  </p>
                </div>

                <div className="flex flex-col text-white text-[6px] z-20 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] text-center">
                  <h1 className="text-xs">Backend</h1>
                  <p className="text-red-400">
                    NodeJs, NextJs, ExpressJs, Python, C#
                  </p>
                </div>

                <div className="flex flex-col text-white text-[6px] z-20 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] text-center">
                  <h1 className="text-xs">DataBase</h1>
                  <p className="text-red-400">AWS, MongoDB, MySQL, SQLite3</p>
                </div>
              </div>
            </div>

            <div
              id="3"
              className="w-[180px] h-[32%] max-h-[400px] bg-black flex-shrink-0 mx-5 duration-500 transition relative"
            >
              <img src={instaBG3} className="w-full h-full absolute z-0"></img>
              <div className="w-9/12 mx-auto pt-12 z-20 flex flex-col gap-2 bg-white font-bold pl-2 text-white">

              <h1 className="text-center underline text-white z-20">
                  {" "}
                  Strengths
                </h1>
                  <div className="flex flex-wrap gap-2">
                  <div className="border border-white rounded-xl bg-gray-200 bg-opacity-25 p-2 py-0.5 z-20 text-[6px] flex justify-center items-center "> 
                  <p className="drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]"> Analytical  </p>
                  </div>

                  <div className="border border-white rounded-xl bg-gray-200 bg-opacity-25 p-2 py-0.5 z-20 text-[6px] flex justify-center items-center "> 
                  <p className="drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]"> Problem Solver  </p>
                  </div>

                  <div className="border border-white rounded-xl bg-gray-200 bg-opacity-25 p-2 py-0.5 z-20 text-[6px] flex justify-center items-center "> 
                  <p className="drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]"> Patient  </p>
                  </div>

                  <div className="border border-white rounded-xl bg-gray-200 bg-opacity-25 p-2 py-0.5 z-20 text-[6px] flex justify-center items-center "> 
                  <p className="drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]"> Fast Learner  </p>
                  </div>

                  <div className="border border-white rounded-xl bg-gray-200 bg-opacity-25 p-2 py-0.5 z-20 text-[6px] flex justify-center items-center "> 
                  <p className="drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]"> Git  </p>
                  </div>

                  <div className="border border-white rounded-xl bg-gray-200 bg-opacity-25 p-2 py-0.5 z-20 text-[6px] flex justify-center items-center "> 
                  <p className="drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]"> Agile  </p>
                  </div>

                  <div className="border border-white rounded-xl bg-gray-200 bg-opacity-25 p-2 py-0.5 z-20 text-[6px] flex justify-center items-center "> 
                  <p className="drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]"> Scrum  </p>
                  </div>
                  </div>
              </div>
            </div>

            <div
              id="4"
              className="w-[180px] max-h-[400px] h-[32%] bg-black flex-shrink-0 mx-5 duration-500 transition relative"
            >
              <img src={instaBG4} className="w-full h-full absolute z-0"></img>

              <div className="w-10/12 mx-auto pt-12 z-20 flex flex-col gap-2 bg-white font-bold ">
                <h1 className="text-center underline text-white z-20 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                  {" "}
                  Langauges{" "}
                </h1>

                <div className=" flex flex-col gap-2 text-white text-[6px] z-20 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                  <h1 className="text-xs text-center">Swedish </h1>
                  <div className="flex w-full justify-center gap-1">
                      <AiFillStar color={"#F8719D"} size={10}/>
                      <AiFillStar color={"#F8719D"} size={10}/>
                      <AiFillStar color={"#F8719D"} size={10}/>
                      <AiFillStar color={"#F8719D"} size={10}/>
                      <AiFillStar color={"#F8719D"} size={10}/>

                  </div>

                  <h1 className="text-xs text-center">English </h1>
                  <div className="flex w-full justify-center gap-1">
                      <AiFillStar color={"#F8719D"} size={10}/>
                      <AiFillStar color={"#F8719D"} size={10}/>
                      <AiFillStar color={"#F8719D"} size={10}/>
                      <AiFillStar color={"#F8719D"} size={10}/>
                      <AiFillStar color={"#F8719D"} size={10}/>

                  </div>


                  <h1 className="text-xs text-center">Arabic </h1>
                  <div className="flex w-full justify-center gap-1">
                      <AiFillStar color={"#F8719D"} size={10}/>
                      <AiFillStar color={"#F8719D"} size={10}/>
                      <AiFillStar color={"#F8719D"} size={10}/>
                      <AiFillStar color={"#F8719D"} size={10}/>
                      <AiFillStar color={"#F8719D"} size={10}/>

                  </div>
                </div>
              </div>
            </div>

            <div id="8" className="w-[180px] h-[32%] flex-shrink-0 mx-5"></div>
            <div id="8" className="w-[180px] h-[32%] flex-shrink-0 mx-5"></div>
          </div>
        </div>
      </div>
    </>
  );
}
